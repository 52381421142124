import React from 'react';
import '../../css/sections/TravelSection.css';

import residenceinn from '../../images/hotels/residenceinn.jpg';
import courtyard from '../../images/hotels/courtyard.jpg';
//import hilton from '../../images/hotels/hilton.jpg';
import marriott from '../../images/hotels/marriott.jpg';

interface Hotel {
  name: string;
  image: string;
  address: string;
  mapUrl: string;
  rsvpLink: string;
  checkinTime: string;
  checkoutTime: string;
  lastDayToBook: string;
  isFullyBooked?: boolean; // Add this new property
  rsvpOutsideBlockLink?: string; // Add this new property
}

const hotels: Hotel[] = [
  {
    name: "Richmond Marriott",
    image: marriott,
    address: "500 E Broad St, Richmond, VA 23219",
    mapUrl: "https://maps.app.goo.gl/rb1DjGEBjqXP7sWZ9",
    rsvpLink: "https://book.passkey.com/event/50881806/owner/12987/home",
    checkinTime: "4:00 PM",
    checkoutTime: "11:00 AM",
    lastDayToBook: "12/20/2024",
    isFullyBooked: true, // Set this to true for fully booked hotels
    rsvpOutsideBlockLink: "https://www.marriott.com/reservation/availabilitySearch.mi?destinationAddress.country=&lengthOfStay=2&fromDate=01%2F17%2F2025&toDate=01%2F19%2F2025&numberOfRooms=1&numberOfAdults=1&guestCountBox=1+Adults+Per+Room&childrenCountBox=0+Children+Per+Room&roomCountBox=1+Rooms&childrenCount=0&childrenAges=&clusterCode=none&corporateCode=&groupCode=&isHwsGroupSearch=true&propertyCode=RICDT&useRewardsPoints=false&flexibleDateSearch=false&t-start=01%2F17%2F2025&t-end=01%2F19%2F2025&fromDateDefaultFormat=01%2F17%2F2025&toDateDefaultFormat=01%2F19%2F2025&fromToDate_submit=01%2F19%2F2025&fromToDate="
  },
  {
    name: "Courtyard by Marriott",
    image: courtyard,
    address: "1320 E Cary St, Richmond, VA 23219",
    mapUrl: "https://maps.app.goo.gl/qxLw3zG8nL6LP9x46",
    rsvpLink: "https://www.marriott.com/event-reservations/reservation-link.mi?id=1723735357282&key=GRP&app=resvlink",
    checkinTime: "4:00 PM",
    checkoutTime: "12:00 PM",
    lastDayToBook: "12/20/2024",
    isFullyBooked: false, // Set this to true for fully booked hotels
    rsvpOutsideBlockLink: "https://www.marriott.com/reservation/availabilitySearch.mi?destinationAddress.country=&lengthOfStay=2&fromDate=01%2F17%2F2025&toDate=01%2F19%2F2025&numberOfRooms=1&numberOfAdults=1&guestCountBox=1+Adults+Per+Room&childrenCountBox=0+Children+Per+Room&roomCountBox=1+Rooms&childrenCount=0&childrenAges=&clusterCode=none&corporateCode=&groupCode=&isHwsGroupSearch=true&propertyCode=RICRL&useRewardsPoints=false&flexibleDateSearch=false&t-start=01%2F17%2F2025&t-end=01%2F19%2F2025&fromDateDefaultFormat=01%2F17%2F2025&toDateDefaultFormat=01%2F19%2F2025&fromToDate_submit=01%2F19%2F2025&fromToDate=",
  },
  {
    name: "Residence Inn by Marriott",
    image: residenceinn,
    address: "1320 E Cary St, Richmond, VA 23219",
    mapUrl: "https://maps.app.goo.gl/U7xQ6mVcPLTPTgwGA",
    rsvpLink: "https://www.marriott.com/event-reservations/reservation-link.mi?id=1723735357282&key=GRP&app=resvlink",
    checkinTime: "4:00 PM",
    checkoutTime: "12:00 PM",
    lastDayToBook: "12/20/2024",
  },
];

interface AdditionalHotel {
  name: string;
  address: string;
  websiteUrl: string;
  distanceToMarriott: string;
}

const additionalHotels: AdditionalHotel[] = [
  {
    name: "Hilton Richmond Downtown",
    distanceToMarriott: "across the street from the Marriott",
    address: "501 E Broad St, Richmond, VA 23219",
    websiteUrl: "https://www.hilton.com/en/hotels/ricrdhh-hilton-richmond-downtown/?SEO_id=GMB-AMER-HH-RICRDHH&y_source=1_MjA4NDM1OS03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D"
  },
  {
    name: "Moxy Richmond Downtown",
    distanceToMarriott: "5 minute walk to the Marriott",
    address: "501 East Franklin Street, Richmond, VA 23219",
    websiteUrl: "https://www.marriott.com/en-us/hotels/ricox-moxy-richmond-downtown/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0"
  },
  {
    name: "Omni Richmond Hotel",
    distanceToMarriott: "4 minute drive to the Marriott",
    address: "100 South 12th Street, Richmond, VA 23219",
    websiteUrl: "https://www.omnihotels.com/hotels/richmond?utm_source=gmblisting&utm_medium=organic"
  },
  {
    name: "Quirk Hotel Richmond",
    distanceToMarriott: "2 minute drive to the Marriott",
    address: "201 West Broad Street, Richmond, VA 23220",
    websiteUrl: "https://www.quirkhotels.com/?utm_source=google&utm_medium=organic&utm_campaign=business_listing",
  },
  {
    name: "The Jefferson Hotel",
    distanceToMarriott: "3 minute drive to the Marriott",
    address: "101 West Franklin Street, Richmond, VA 23220",
    websiteUrl: "https://www.jeffersonhotel.com/",
  }
];

export const TravelSection: React.FC = () => (
  <div className="section-section">
    <div className="section-container">
      <div className="travel-info">
        <h2>Travel & Hotels</h2>
        
        <h3>Flying</h3>
        <p>We recommend flying into the Richmond International Airport (RIC), which is approximately a 15 minute drive (10 miles) to the Richmond Marriott. There are many ways to get downtown once you arrive, including car rental options and ride sharing apps, such as Lyft and Uber.</p>
        
        <h3>Additional Airport Options</h3>
        <p>If renting a car, there are additional airport options which are approximately a 2-2.5h drive to the Richmond Marriott:</p>
        <ul>
          <li>Ronald Reagan Washington National Airport (DCA), 107 miles</li>
          <li>Dulles International Airport (IAD), 120 miles</li>
          <li>Raleigh-Durham International Airport (RDU), 163 miles</li>
        </ul>
        
        <h3>Local Transportation</h3>
        <p>There will be a shuttle providing service from the Courtyard & Residence Inn to the main venue location at the Richmond Marriott.</p>
        
        <h3>Parking</h3>
        <p>There are 5 ramps (4,500 spots) within a 5 minute walk of the Richmond Marriott hotel owned by the City of Richmond.</p>
        <ul>
          <li>Weekdays: $5/hour, maximum $20 per day</li>
          <li>Weekends: Flat rate of $8 per day</li>
        </ul>
        <p>You can view the <a href="https://www.pshs.virginia.gov/media/governorvirginiagov/secretary-of-public-safety-and-homeland-security/pdf/RIC_Parking.pdf" target="_blank" rel="noopener noreferrer">City's Downtown Parking Map</a> for more information.</p>
      </div>
      
      <div className="hotel-info">
        <h3>Hotels</h3>
        <p className="hotel-note">We currently have blocks at 3 hotels. Due to other events in the area the same weekend, block sizes are limited. You may be able to book a room at the hotel outside a block, but we cannot guarantee availability and pricing. There are also many other hotel options in the area. We have listed some popular choices at the bottom of this page.</p>
        <p className="hotel-note"><b>Note:</b> Regarding Marriott hotels, any early check-in options will be unlikely (even for high rewards members, such as Platinum and above). If a morning check-in is desired, booking the night before is strongly encouraged. </p>
        <div className="hotel-cards-container">
          {hotels.map((hotel, index) => (
            <div key={index} className={`hotel-card ${hotel.isFullyBooked ? 'fully-booked' : ''}`}>
              {/* {hotel.isFullyBooked && <div className="fully-booked-badge">Fully Booked</div>} */}
              <img className="hotel-card-image" src={hotel.image} alt={hotel.name} />
              <div className="hotel-card-content">
                <div>
                  <h3>{hotel.name}</h3>
                  <p className="hotel-address">{hotel.address}</p>
                  <p className="hotel-details">
                    Check-in: {hotel.checkinTime} <br /> Check-out: {hotel.checkoutTime}
                    <br />
                    Last Day to Book: {hotel.lastDayToBook}
                  </p>
                </div>
                <div className="hotel-links">
                  <a href={hotel.mapUrl} target="_blank" rel="noopener noreferrer">Map</a>
                  <a 
                    href={hotel.isFullyBooked ? '#' : hotel.rsvpLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={hotel.isFullyBooked ? 'disabled' : ''}
                    onClick={(e) => hotel.isFullyBooked && e.preventDefault()}
                  >
                    {hotel.isFullyBooked ? 'Block is booked' : 'Book using our block'}
                  </a>
                  {hotel.isFullyBooked && <a href={hotel.rsvpOutsideBlockLink} target="_blank" rel="noopener noreferrer">Book outside our block</a>}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="additional-hotels">
          <h3>Additional Hotels</h3>
          <p>There are many other hotels in the area. Here are some of the most popular options:</p>
          <ul className="additional-hotels-list">
            {additionalHotels.map((hotel, index) => (
              <li key={index} className="additional-hotel-item">
                <a 
                  href={hotel.websiteUrl} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {hotel.name}
                </a>
                &nbsp;- {hotel.distanceToMarriott}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);